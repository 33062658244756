import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-push-notification-error-modal',
  templateUrl: './push-notification-error-modal.component.html',
  styleUrls: ['./push-notification-error-modal.component.scss']
})
export class PushNotificationErrorModalComponent implements OnInit {

  excelUrl: string = ''
  constructor(private config: DynamicDialogConfig) { }

  ngOnInit(): void {
    this.excelUrl = this.config.data.url
  }

}
