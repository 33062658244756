import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-push-notification-success-modal',
  templateUrl: './push-notification-success-modal.component.html',
  styleUrls: ['./push-notification-success-modal.component.scss']
})
export class PushNotificationSuccessModalComponent implements OnInit {

  message: string = ''
  constructor(private config: DynamicDialogConfig) { }

  ngOnInit(): void {
    this.message = this.config.data.message
  }

}
