import {environment} from '../environments/environment';

export class AppSettings {

  /**
   * Prefijo de variables de LocalStorage
  */
   public static get localStoragePrefix(): string {
    return 'itx';
  }

  /**
   * Prefijo de variable de autenticación de LocalStorage
  */
  public static get authTokenLocalStorageKey(): string {
      return `${this.localStoragePrefix}-auth`;
  }

  /**
   * Prefijo de variable de autenticación de LocalStorage
  */
  public static get authQuestTokenLocalStorageKey(): string {
      return `${this.localStoragePrefix}-quest-auth`;
  }

  /**
     * Host para el servicios de usuario
     */
   public static get userHost(): string {
    return `${environment.api.host}/user`;
  }

  /**
     * Host para los servicios de exportación de usuario
     */
   public static get userExpoHost(): string {
    return `${environment.api.IEhost}/user`;
  }

  /**
    * Host para invocar peticiones de códigos postales para México (país)
    */
  public static get postalMXHost(): string {
    return `${environment.api.postalCode}/MX`;
  }

  /**
    * Host para el servicios de usuario
    */
  public static get laboratoryHost(): string {
    return `${environment.api.host}/laboratory`;
  }

  /**
    * Host para el servicios de usuario
    */
   public static get laboratoryExpoHost(): string {
    return `${environment.api.IEhost}/laboratory`;
  }

  /**
    * Host para el servicio de farmacia
    */
   public static get drugstoreHost(): string {
    return `${environment.api.host}/drugstore`;
  }

  /**
    * Host para el servicio de orden
    */
  public static get orderHost(): string {
    return `${environment.api.host}/order`;
  }

  /**
    * Host para servicios de reporte
    */
  public static get reportHost(): string {
    return `${environment.api.host}/report`;
  }

  /**
    * Host para servicios de exportación de reportes
    */
  public static get reportImpHost(): string {
    return `${environment.api.IEhost}/report`;
  }

  public static get notificationHost(): string {
    return `${environment.api.host}/notification`;
  }
}
