import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { registerLocaleData } from '@angular/common';
import localeEsMx from '@angular/common/locales/es-MX';

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CustomInterceptor} from './interceptors/custom.interceptor';
import { LabAdminComponent } from './lab-admin/lab-admin.component';
import { PushNotificationErrorModalComponent } from './push-notification-error-modal/push-notification-error-modal.component';
import { PushNotificationSuccessModalComponent } from './push-notification-success-modal/push-notification-success-modal.component';

registerLocaleData(localeEsMx, 'es-Mx');


@NgModule({
  declarations: [
    AppComponent,
    LabAdminComponent,
    PushNotificationErrorModalComponent,
    PushNotificationSuccessModalComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    ToastModule,
    ConfirmDialogModule,
    HttpClientModule
  ],
  providers: [
    MessageService,
    ConfirmationService,
    { provide: HTTP_INTERCEPTORS, useClass: CustomInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es-Mx' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
