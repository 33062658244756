import { Injectable } from '@angular/core';
import { User} from '../models/remote';
import Utilities from '../helpers/utilities';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AppSettings } from '../app.settings';
import { BACKEND_URLS } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private requestUrl = environment.api.host;
  private userRequestURL = AppSettings.userHost;
  private _userToken: string;
  private _currentUser: User;

  private dotNetUserUrl = `${BACKEND_URLS.dotNet}/user`
  private dotNetUrl = `${BACKEND_URLS.dotNet}`

  options = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
    withCredentials: true
  }

  constructor(private httpClient: HttpClient) {
  }

  private processUserResponse(user: User): Promise<User | any> {
    try {
      localStorage.setItem(AppSettings.authTokenLocalStorageKey, JSON.stringify(user));
      this._userToken = localStorage.getItem(AppSettings.authTokenLocalStorageKey);
      this._userToken = user['token'];
      delete user['token'];
      this._currentUser = user;
      return Promise.resolve(user);
    } catch (error) {
      throw {message: 'Tu navegador tiene las cookies deshabilitadas. Asegúrate de tener las cookies habilitadas y prueba de nuevo.'}
    }
  }

  private invokeLogin(identity: string, password: string): Promise<User | any> {
    return this.httpClient.post<any>(`${this.requestUrl}/loginWeb?email=${identity}&password=${password}`, this.options).toPromise();
  }

  public login(identity: string, password: string): Promise<User | any> {
    return this.invokeLogin(identity, password).then((user: User) => {
      return this.processUserResponse(user);
    }).catch(error => {
      throw error;
    });
  }

  private invokeSignin(identity: string, password: string): Promise<User | any> {
    return this.httpClient.post<any>(`${this.requestUrl}/signinWeb?email=${identity}&password=${password}`, this.options).toPromise();
  }

  public signin(identity: string, password: string): Promise<User | any> {
    return this.invokeSignin(identity, password).then((user: User) => {
      return this.processUserResponse(user);
    }).catch(error => {
      throw error;
    });
  }

  private invokeGetRecoveryPasswordCode(identity: string, option): Promise<string | any> {
    const requestBody = {
      dataToRecover: identity,
      option,
      sender: 'web'
    }
    return this.httpClient.post<any>(`${this.dotNetUrl}/recoveryPasswordCode`, requestBody).toPromise();
  }

  public getRecoveryPasswordCode(identity: string, option): Promise<any> {
    return this.invokeGetRecoveryPasswordCode(identity, option)
  }

  public verifyResetPasswordCode(recoveryCode: number): Promise<any> {
    return this.httpClient.post<any>(`${this.dotNetUrl}/validateRecoveryCode`, {recoveryCode}).toPromise();
  }

  public resetPassword(recoveryCode: number, newPassword: number): Promise<string> {
    return this.httpClient.post<any>(`${this.dotNetUrl}/resetPasswordByCode`, {recoveryCode, newPassword}).toPromise();
  }

  private invokeIsAuthenticated(): Promise<boolean> {
    return this.httpClient.get<boolean>(`${this.dotNetUrl}/isAuthenticated`).toPromise();
  }

  public isAuthenticated(): Promise<boolean> {
    return this.invokeIsAuthenticated().then(isTokenValid => {
      if(!isTokenValid)
        this.logout()
      return isTokenValid
    }).catch(error => {
      return false
    })
  }

  public logout(): Promise<void> {
    // The invoker is responsible for navigating the user back to Public pages
    this._userToken = '';
    this._currentUser = null;
    if (this.getLocalUserToken()) {
        localStorage.removeItem(AppSettings.authTokenLocalStorageKey);
        return this.httpClient.put<any>(`${this.dotNetUserUrl}/logout`, this.options).toPromise();
    }
}

  public getLocalUserToken() {
    try {
      let localData: any;
      if (!this._userToken && localStorage.getItem(AppSettings.authTokenLocalStorageKey)) {
          localData = JSON.parse(localStorage.getItem(AppSettings.authTokenLocalStorageKey));
          this._userToken = localData.token;
      }
      return this._userToken;
    } catch(error) {
      throw {message: 'Tu navegador tiene las cookies deshabilitadas. Asegúrate de tener las cookies habilitadas y prueba de nuevo.'}
    }
  }

  public getLocalUser() {
    try {
      if (!this._currentUser && localStorage.getItem(AppSettings.authTokenLocalStorageKey)) {
        this._currentUser = JSON.parse(localStorage.getItem(AppSettings.authTokenLocalStorageKey));
      }
      return this._currentUser;
    } catch(error) {
      throw {message: 'Tu navegador tiene las cookies deshabilitadas. Asegúrate de tener las cookies habilitadas y prueba de nuevo.'}
    }
  }

  public checkFunctionPermission(functionObjectId: string): Promise<boolean> {
    return this.httpClient.get<boolean>(`${this.dotNetUserUrl}/checkFunctionPermission?functionObjectId=${functionObjectId}`)
    .toPromise()
  }

  public checkFunctionLabPermission(functionObjectId: string): Promise<boolean> {
    return this.httpClient.get<boolean>(`${AppSettings.userHost}/checkFunctionLabPermission?functionObjectId=${functionObjectId}`)
    .toPromise()
  }

  public confirmAccount(confirmationCode: string): Promise<any> {
    let formData: FormData = new FormData;
    formData.append('confirmationCode', confirmationCode);
    return this.httpClient.post<any>(`${this.dotNetUrl}/validateConfirmationCode`, formData).toPromise();
  }

}
