import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment';
import { QuestService } from '../services/data/quest.service';
import { BACKEND_URLS } from '../constants';

@Injectable()
export class CustomInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private questService: QuestService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // add authorization header with token if available

    if(request.url.indexOf('flag=0') === -1) {
      const questUserToken = this.questService.getLocalUserToken();
      if (questUserToken && (request.url.includes(`${environment.api.host}/quest-user`) || request.url.includes(`${environment.api.host}/quest-user`) || request.url.includes(`${BACKEND_URLS.dotNet}/quest-user`))) {
        request = request.clone({
            setHeaders: {
                'Authorization': `${questUserToken}`
            }
        });
        return next.handle(request);
      }

      const userToken = this.authService.getLocalUserToken();
      if (userToken && (request.url.includes(environment.api.host) || request.url.includes(environment.api.IEhost) || request.url.includes(BACKEND_URLS.dotNet))) {
          request = request.clone({
              setHeaders: {
                  'Authorization': `${userToken}`
              }
          });
      }
    }
    return next.handle(request);
  }
}
